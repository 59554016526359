import React from "react";

const bannerStyles = {
    transform: "rotate(-45deg)",
    position: "absolute",
    backgroundColor: "red",
    color: "#fff",
    zIndex: 1024,
    /* padding-top: 2em; */
    top: 30,
    left: "-50px",
    boxShadow: "1px 1px 1px rgb(0 0 0 / 40%)",
    width: 200,
    fontSize: "x-large"
};

const SoldBanner = () => {
    return (
        <div class="sold-banner" style={bannerStyles}><span>Sold</span></div>
    )
};

export default SoldBanner;